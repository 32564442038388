import React from 'react';
import cedarfw from '../img/cedar-fw.svg';
import CodeImg from '../img/code.png'
import styled from "styled-components";

const Solutions = () => {
    return (
        <BasicLayout id="Solutions">
            <Column>
                <FrameworkLogo>
                    <img src={cedarfw} alt="plant" />
                    <h3>cedar</h3>
                    <h2>framework</h2>
                </FrameworkLogo>
                <Tag onClick={(event)=>{ window.open('https://github.com/cedardeployer/cedar', '', '')}}>Open Source</Tag>
                <img src={CodeImg} alt="plant" />
            </Column>
            <VerticalSeparator></VerticalSeparator>
            <Column>
                <FrameworkLogo>
                    <img src={cedarfw} alt="plant" />
                    <h3>cedar</h3>
                    <h2>framework</h2>
                </FrameworkLogo>
                <Tag>Private Beta</Tag>
                <BodyTypography>Output to Ansible and Terraform</BodyTypography>
            </Column>
        </BasicLayout>
    )
}

const BasicLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    padding: 80px 120px;
    background-color: #D8E8C3;
    @media (max-width: 812px) {
        display: block;
        text-align: center;
        padding: 40px 24px;
    }
    h1 {
        font-size: 28px;
        
    }
`
const FrameworkLogo = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    h3{
        line-height: 16px;
        margin-top: 16px;
    }
`
const Column = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 812px) {
        padding: 40px 24px;
        background-color: white;
        margin-bottom: 24px;
        border-radius: 20px;
    }
    img {
        display: none;
    }
`
const Tag = styled.div`
    background-color: #FDFFD4;
    padding: 16px;
    font-size: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 10px;
`
const BodyTypography = styled.h3`
    color: black;
`
const VerticalSeparator = styled.div`
    width: 1px;
    background-color: #688A57;
    margin-left: 32px;
`

export default Solutions;
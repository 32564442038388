import React from 'react';
import styled from 'styled-components';
import CedarLogo from '../img/cedar-logo.svg';
import { Link } from "react-scroll";

const NavBar = () => {
    return(
       <StyledNAv>
           <img src={CedarLogo} alt="" />
           <Sections>
                <Link
                    activeClass="active"
                    to="Main"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Home   
                </Link>
                <Link
                    activeClass="active"
                    to="WhyCedar"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Why cedar   
                </Link>
                <Link
                    activeClass="active"
                    to="Solutions"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Solutions   
                </Link>
                <VerticalSeparator></VerticalSeparator>
                <Link
                    activeClass="active"
                    to="ContactUs"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}

                >
                    Contact Us   
                </Link>
           </Sections>
       </StyledNAv>
    )
}

const StyledNAv = styled.nav`
    z-index: 1;
    position: fixed;
    width: 100%;
    top: 0;
    min-height: 10vh;
    display: flex;
    margin: auto;
    justify-content: space-between;
    background-color: black;
    padding: 0px 120px;
    align-items: center;
    a {
        color: #B5B5B5;
        text-decoration:none;
        margin-left: 32px;
        cursor: pointer;
        &:hover {
            color: #688A57;
        }
    }
    @media (max-width: 812px) {
        display: block;
        min-height: auto;
        padding: 24px 24px;
        text-align: center;
        a {
            margin-left: unset;
        }
    }
    
`
const Sections= styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 812px) {
        margin-right: 0px;
        margin-top: 24px;
        display: flex;
        justify-content: space-around;
    }

`

const VerticalSeparator = styled.div`
    width: 1px;
    background-color: #688A57;
    margin-left: 32px;
    @media (max-width: 812px) {
        display: none;
    }
`

export default NavBar;
import React from "react";
import Landing from './pages/landing';
import GlobalStyles from './components/globalStyles';
import NavBar from './components/Nav';


function App() {
  return (
    <div className="App">
      <NavBar />
      <GlobalStyles />
      <Landing />
    </div>
  );
}

export default App;

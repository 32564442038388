import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle `
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito';
}
body {
    font-family: 'Nunito';
    overflow-x:hidden;
}
html {
    @media (max-width: 812px) {
        font-size: 80%;
        display: block;
        overflow-x:hidden;
    }
}
h1{
    font-size: 40px;
    font-weight: black;
    font-family: 'Nunito';
}
h2{
    font-size: 32px;
    font-weight: 400;
}
h3{
    font-size: 24px;
    font-weight: 400;
}
button {
    font-weight: 400;
    font-size: 24px;
    padding: 24px;
    border-radius: 10px;
    border: unset;
    background-color: #688A57;
    color: white;
    transition: all 0.5s ease;
    outline: none;
    cursor: pointer;
    border-radius: 16px;
    &:hover{
        background-color:white;
        color: #688A57;
        border: 1px solid #688A57; 
    }
}

`;

export default GlobalStyles;
import React from 'react';
import styled from "styled-components";

const ContactUs = () => {
    return(
        <BasicLayout id="ContactUs">
            <Container>
                <h1>Contact US </h1>
                <HorizontalSeparator />
                <form action="">
                    <DefaultInput>
                        <label htmlFor="">WORK EMAIL</label>
                        <input type="text" />
                    </DefaultInput> 
                    <DefaultInput>
                        <label htmlFor="">FULL NAME</label>
                        <input type="text" />
                    </DefaultInput>
                    <DefaultInput>
                        <label htmlFor="">TELL US MORE</label>
                        <textarea className="textArea" name="" id="" cols="30" rows="6"></textarea>
                    </DefaultInput>
                    <DefaultButton>Send</DefaultButton>        
                </form>
            </Container>
        </BasicLayout>
    )
}

const BasicLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 120px;
    background-color: black;
    @media (max-width: 812px) {
        background-color: white;
        padding: 40px 24px;
        h1 {
            font-size: 28px;
        }
    }
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 24px 40px; 
    border-radius: 20px;
`
const HorizontalSeparator = styled.div`
    margin: 24px 0px;
    height:1px;
    width: 100%;
    background-color: #688A57;
`
const DefaultInput = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px; 
    label {
        font-size: 10px;
        margin-bottom: 8px;
        font-weight: bold;
    }
    input {
        min-width: 350px;
        height: 24px;
        padding: 24px;
        background-color: #FDFFD4;
        border: unset;
        font-size: 16px;
        box-sizing: border-box;
        outline: none;
        border-radius: 10px;
        :focus {
            outline: solid 3px #D8E8C3;
        }
    }
    textArea {
        background-color: #FDFFD4;
        border: unset;
        padding: 24px;
        border-radius: 10px;
        :focus {
            outline: solid 3px #D8E8C3;
        }
    }

`
const DefaultButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    min-width: 120px;
    outline: none;
`

export default ContactUs;
import React from 'react';
import Hero from '../components/hero';
import WhyCedar from '../components/whycedar';
import Solutions from '../components/Solutions';
import ContactUs from '../components/ContactUs'
import GlobalStyles from '../components/globalStyles';

const Landing = () => {
    return(
        <div>
            <GlobalStyles />
            <Hero/>
            <WhyCedar/>
            <Solutions/>
            <ContactUs/>
        </div>
    );
}

export default Landing;
import React from 'react';
import cedarfw from '../img/cedar-fw.svg';
import awsLogo from '../img/aws-logo.svg';
import sectionDecoration from '../img/section-decoration.svg';
import styled from "styled-components";
import {motion} from "framer-motion";

const Hero = () => {
    const TitleAnimation = {
        hidden: {opacity: 0 },
        show: {opacity: 1, transition: {duration:2} },
    };
    const ContainerAnimation = {
        hidden: {opacity: 0  },
        show: {opacity: 1, transition: {duration:1} },
    };
    const DecorationAnimationLeft = {
        hidden: {x: -100, opacity: 0  },
        show: {x: 0, opacity: 1, transition: {duration:1} },
    };
    const DecorationAnimationRight = {
        hidden: {x: 100, opacity: 0  },
        show: {x: 0, opacity: 1, transition: {duration:1} },
    };
    const ButtonAnimation = {
        hidden: {opacity: 0 },
        show: {opacity: 1, transition: {duration:1} },
    };

    

    return (
        <Landing id="Main" >
            <BigHearyStatement>
                <motion.div variants={ContainerAnimation} initial="hidden" animate="show">
                    <FrameworkLogo>
                        <img src={cedarfw} alt="plant" />
                        <h3>cedar</h3>
                        <h2>framework</h2>
                    </FrameworkLogo>
                </motion.div>
                <div className="statement-container">
                    <motion.h1 variants={TitleAnimation} initial="hidden" animate="show">Automating your way to build and deploy cloud applications</motion.h1>
                </div>
            </BigHearyStatement>
            <CallToAction>
                <motion.div variants={ContainerAnimation} initial="hidden" animate="show">  
                    <SectionHeader>
                        <h1 className="title">Get started</h1>
                        <h3 className="subtitle">Setup your template and deployment settings</h3>
                    </SectionHeader>
                </motion.div>
                <ButtonContainer>
                    <motion.img variants={DecorationAnimationLeft} initial="hidden" animate="show" src={sectionDecoration} alt="Green stripes" />
                    <motion.button variants={ButtonAnimation} initial="hidden" onClick={(event)=>{ window.open('https://github.com/cedardeployer/cedar', '', '')}} animate="show">Deploy now</motion.button>
                    <motion.img variants={DecorationAnimationRight} initial="hidden" animate="show" src={sectionDecoration} alt="Green stripes" />
                </ButtonContainer>
                <img src={awsLogo} alt="aws logo" />
            </CallToAction>
        </Landing>
    );
}
const Landing = styled.div`
    display:grid;
    grid-template-rows: 1fr 1fr;
    height: 100vh;
    @media (max-width: 812px) {
        height: unset;
        text-align: center;
        display: block;
        margin-top: 15vh;
        display:flex;
        flex-direction: column;
        align-items: center;
        h1 {
            font-size: 28px;
        }
    }
`
const BigHearyStatement = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: black;
    h1,h2,h3 {
        color: white;
    }
    h1{
        margin-top: 32px;
    }
    @media (max-width: 812px) {
        padding: 40px 24px;
    }
`
const FrameworkLogo = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    h3{
        line-height: 16px;
        margin-top: 16px;
    }
`
const CallToAction = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    background-color: #FDFFD4;
    h2,h3 {
        color: #594B32;
    }
    h1{
        margin-top: 32px;
        color: balck;
    }
    @media (max-width: 812px) {
        padding: 40px 24px;
    }
`
const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 812px) {
        display:flex;
        justify-content: center;
        align-items: center;
        button {
            margin-top: 24px;
        }
        img {
            display: none;
        }
    }
`

export default Hero;
import React from 'react';
import cedarFromImg from '../img/cedar-form.png';
import styled from "styled-components";

const WhyCedar = () => {
    return (
        <BasicLayout id="WhyCedar">
            <SectionHeader>
                <h1 className="title"> Why <span>CEDAR </span>?</h1>
                <h3 className="subtitle">You don't have to code any templates</h3>
                <HorizontalSeparator />
                <BodyTypography>CEDAR will create the Templates for you and deploy to any account defined in your configuration.</BodyTypography>
            </SectionHeader>
            <ImgContainer>
                <img src={cedarFromImg} alt="" />
            </ImgContainer>
        </BasicLayout>
    )
}

const BasicLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 80px 120px;
    @media (max-width: 812px) {
        display: block;
        text-align: center;
        padding: 40px 24px;
    }
    h1 {
        font-size: 28px;
        
    }
`
const ImgContainer = styled.h3`
    display:flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 812px) {
        width: 100%;
        margin-top: 24px;
        img {
            width: 90%; 
        }
    }
`
const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    span {
        color: #688A57;
    }
    
`

const HorizontalSeparator = styled.div`
    margin: 24px 0px;
    height:1px;
    width: 100%;
    background-color: #688A57;
`
const BodyTypography = styled.h3`
    color: black;
`

export default WhyCedar;